@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.addtocart-sidebar::-webkit-scrollbar {
  display: none;
}
.order-details-container::-webkit-scrollbar {
  display: none;
}
.order-details-container::-webkit-scrollbar-thumb {
  height: 10rem;
  background-color: #232323;
  border-radius: 50px;
}
.order-details-container::-webkit-scrollbar {
  width: 54px;
}

.order-details-container::-webkit-scrollbar-track {
  background-color: #6c350b;
}
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  height: 5%;
  background-color: #fbdd01;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: azure;
}

::-webkit-scrollbar-track {
  background-color: #f7ced7;
}

.wrapper {
  @apply container mx-auto;
}

.title {
  position: relative;
}

.title::after {
  content: "";
  width: 40px;
  height: 3px;
  background-color: #a13860;
  position: absolute;
  left: 0;
  bottom: -2px;
}

.img img:hover {
  transform: scale(1.2);
  transition: 2s;
}

.productCarousel.carousel-root {
  display: flex;
  flex-direction: row-reverse;
  gap: 15px;
}
.productCarousel.carousel-root .carousel {
  width: auto;
}
.productCarousel.carousel-root .carousel.carousel-slider {
  width: 100%;
}
.productCarousel.carousel-root .carousel.carousel-slider .slider-wrapper {
  border-radius: 10px;
}
.productCarousel.carousel-root .carousel .thumbs-wrapper {
  margin: 0;
}
.productCarousel.carousel-root .carousel .thumb {
  height: 60px;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}
.productCarousel.carousel-root .carousel .thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.productCarousel.carousel-root .carousel .thumb:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0;
}
.productCarousel.carousel-root .carousel .thumb.selected,
.productCarousel.carousel-root .carousel .thumb:hover {
  border: 0;
}
.productCarousel.carousel-root .carousel .thumb:hover:after,
.productCarousel.carousel-root .carousel .thumb.selected:after {
  opacity: 0.2;
}
.productCarousel .control-arrow {
  display: none;
}
@media screen and (max-width: 767px) {
  .productCarousel.carousel-root {
    flex-direction: column;
  }
  .productCarousel.carousel-root .carousel .thumb {
    border: 0;
    padding: 0;
  }
}
@media screen and (min-width: 768px) {
  .productCarousel.carousel-root .carousel .thumbs {
    transform: none !important;
    flex-direction: column;
    display: flex;
    gap: 10px;
  }
  .productCarousel.carousel-root .carousel .thumb {
    border: 0;
    padding: 0;
    margin: 0;
  }
}

.swiper-button-next,
.swiper-button-prev {
  color: #6c350b !important;
  stroke: #6c350b !important;
  fill: #6c350b !important;
}
/* Hide scrollbar for WebKit browsers (e.g., Chrome, Safari) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Firefox */
.scrollbar-hide {
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for IE, Edge */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
}
